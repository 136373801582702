site.nav = {
	// Init navigation scripts
	init: function() {
		var $html = $('<div id="mMenu" role="navigation"></div>');
		var $mMenu = $('#mainmenu').find('.mmLevel1').clone().removeAttr('id');
		$html.append($mMenu);
		$('body').append($html);

		// Add expand link to mobile menu
		$('.children', '#mMenu ').prepend('<span class="expand"><i class="fa fa-angle-down"></span>');
		$('.selectedLevel1','#mMenu').addClass('down');
		$('.selectedLevel2','#mMenu').addClass('down');
		$('.selectedLevel3','#mMenu').addClass('down');
		$('.selectedLevel4','#mMenu').addClass('down');
		site.nav.bind();
		site.nav.appendLanguage();
		site.nav.appendQn();


	},
	bind: function() {
		// Mobile menu toggle
		$('#mainmenuToggle').click(function(e) {
			e.stopPropagation();
			e.preventDefault();

			var menuOpen = $('body').hasClass('mainmenu-open');
			site.nav.mobileToggle(e, menuOpen);

			$('#mMenu').on('click', function(e) {
				e.stopPropagation();
			});

			$('body.mainmenu-open').on('click', function (e) {
				$('body').removeClass('mainmenu-open');
			});


		});

		// Expand in mobile menu
		$('.expand', '#mMenu').bind('click', function(e) {
			e.preventDefault();
			$(this).closest('li').toggleClass('down');
		});
	},
	// Toggle mobile menu
	mobileToggle: function(e, menuOpen) {
		$('body').toggleClass('mainmenu-open');
		$(this).toggleClass('open');
	},
	// Append language to site
	appendLanguage: function() {
		var $divisions = $('.wrap ul', '#qn');
		var $menu = $('ul.mmLevel1', '#mMenu');

		var $html = $('<li class="ele-language si level1"></li>');
		$divisions.find('li').each(function() {
			var $li = $(this).clone();
			$html.append($li.find('a'));
		});

		$menu.append($html);
	},

	appendQn: function() {
		var $menu = $('#mMenu'),
			$qn = $('.qn2 ul', '#qn').clone().addClass('menu');
		$('li', $qn).addClass('level1');
		$menu.append($qn);
	}
};

site.inits.push('site.nav.init()');
