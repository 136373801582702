var site = site || {};
site.inits = ['site.search.init()'];

site.search = {};

site.search.init = function() {
	$('#qn ul li.search i').click(function() {
		$('body').toggleClass('search-open');
	});
};

site.window = {
	scrollPosition: 0
};

site.welcome = {
	init: function() {
		// site.welcome.box1 = $('body.frontpage #main .box_ord_3');
		// site.welcome.box2 = $('body.frontpage #main .box_ord_4');
		// $facebook = $('.box_ord_3 ul.facebook').clone();
		//
		// $('.box_ord_4 .boxText').append($facebook);
	},

	resize: function() {
		// setTimeout(function() {
		// 	site.welcome.box1.css('height',"");
		// 	site.welcome.box2.css('height',"");
		// 	if(site.welcome.box1.height() > site.welcome.box2.height()) {
		// 		site.welcome.box2.height(site.welcome.box1.height());
		// 	}else {
		// 		site.welcome.box1.height(site.welcome.box2.height());
		// 	}
		// },700);
	}
};


site.device = {
	// Check if device is a touch device
	isTouch: function() {
		try {
			document.createEvent("TouchEvent");
			return true;
		} catch (e) {
			return false;
		}
	}
};


// A few layout methods
site.layout = {
	// Wrap elements into a div.row
	rowWrap: function(selectorBegin, selectorEnd, extraClasses) {
		if (selectorEnd == undefined) {
			selectorEnd = selectorBegin;
		}

		if (extraClasses == undefined) {
			extraClasses = '';
		}

		$(selectorBegin).nextUntil(selectorEnd).andSelf().add(selectorEnd)
			.wrapAll("<div class='row " +extraClasses+ "'></div>");
	}
}


site.owl = {
	// Shorthand to init owlcarousel
	init: function(id, options) {
		var $owl = $(id);
		if ($owl.length > 0) {
			$owl.addClass('owl-carousel'); // This is to fix a change made in owl 2.0.0-beta.3
			$owl.owlCarousel(options);
		}
	}
};


site.extras = {};
site.extras.initDone = false;
/*
 Extras
 */
site.extras.upload = {
	init: function (urls, lang) {
		if (site.extras.initDone === false) {
			site.extras.initDone = true;
			$('ul#images').sortable({
				'opacity': 0.8,
				'update': function (e, ui) {
					var $el = $(this);
					$el.sortable('disable').addClass('disabled');
					$.post(urls.sort, $(this).sortable('serialize'), function (d) {
						if (d.success !== true) {
							moya.message.add(lang.galleryErrorOnSave);
							$el.sortable('cancel');
						}
						$el.removeClass('disabled');
						$el.sortable('enable');
					});
				}
			});
			$('#ffile').uploadify({
				'uploader': '/_moya/js/jquery/uploadify/uploadify.swf',
				'script': urls.script,
				'cancelImg': '/_moya/js/jquery/uploadify/cancel.png',
				'auto': true,
				'multi': true,
				'folder': '/tmp',
				'fileDesc': lang.galleryImageUploadFileDescription,
				'fileExt': '*.jpg;*.jpeg;*.gif;*.png;*.JPG;*.JPEG;*.GIF;*.PNG',
				'buttonText': lang.galleryImageAdd,
				'queueID': 'ffileQueue',
				'onError': function () {
					$('#notice').html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
				},
				'onComplete': function (e, q, f, r) {
					var n = $('#notice');
					switch (r) {
						case 'noAlbum':
							n.html('<div class="message"><div class="error">' + lang.galleryErrorNoAlbumName + '</div></div>');
							break;
						case 'noSave':
							n.html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
							break;
					}
				},
				'onAllComplete': function () {
					window.location.reload();
				}
			});
		}
	}
};

site.subMenuHeight = {};
site.subMenuHeight  = {
	// Shorthand to init owlcarousel
	init: function() {
		var height1 = $('.subMenu > ul').height();
		var height2 = $('.subMenu .mmLevel2').height();
		$('.subMenu').css('height', height1 + height2);
	}
};

// Run all the inits \o/
$(function() {
	$.each(site.inits, function(i, value) {
		var fun = new Function(value);
		fun();
	});

	//Responsive youtube and vimeo iframe
	$('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]', '#main').each(function() {
		$(this).attr('allowfullscreen','').wrap('<div class="video" style="max-width:'+$(this).width()+'px"><div class="embed-responsive embed-responsive-16by9"></div></div>');
	});

	// Responsive tables
	if (!$('body').is('.admin')) {
		$('table', '#main').wrap('<div class="table-responsive"></div>');
	}

	// Touch login
	$('.login').dblclick(function(e) {
		e.preventDefault();
		window.location = '/moya';
	});

	site.owl.init("#promo ul", {
		items: 1,
		loop:true,
		dots:true
	});
	
	site.owl.init(".box_ord_3 .items", {
		items: 1,
		loop:true,
		nav: true,
		navText: ["",""]
	});

	site.owl.init("#extras .items .images ul.owlCar", {
		items: 1,
		loop:true,
		nav: true,
		navText: ["",""]
	});

	site.owl.init("#extras .itemView .images ul", {
		items: 1,
		loop:true,
		nav: true,
		navText: ["",""]
	});
	site.welcome.init();
	site.welcome.resize();
	if($('.subLevel3 .subMenu').length > 0 ) {
		site.subMenuHeight.init();
	}

	// Reorder language menu based on selected
	// Move is to the top
	//$li = $('.selected', '#divisions');
	//$ul = $('.menu', '#divisions');
	//$ul.prepend($li);
});

$(window).resize(function() {
	site.welcome.resize();
});
