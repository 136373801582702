site.searchBox = {
	init: function() {
		var $body = $('body'),
		$s = $('#searchBox');
		$s.on('click', function (e) {
			if (!$body.hasClass('searchbox-open')) {
				e.preventDefault();
				$body.toggleClass('searchbox-open');
				$s.find('#q').focus();
			}

			$('body.searchbox-open').on('click touchstart', function (e) {
				if (!$(e.target).parents('#searchBox').length) {
					$('body').removeClass('searchbox-open');
				}
			});
		});
		site.searchBox.mobileSearch();
	},
	mobileSearch: function() {
		var $mmenu = $('#mMenu'),
			$mSearchBox = $('#searchBox').clone().removeAttr('id').attr('id','mSearchBox');
		$mmenu.prepend($mSearchBox);

		$('[id]', $mSearchBox).each(function() {
			var $this = $(this),
				$id = $this.attr('id'),
				$newId = 'm'+$id.substr(0,1).toUpperCase()+$id.substr(1),
				$label = $('label[for="'+$id+'"]', $mSearchBox);

			if ($label.length > 0) {
				$label.attr('for', $newId);
			}
			$this.attr('id', $newId);

			if (typeof $this.attr('name') !== typeof undefined) {
				$this.attr('name', $newId);
			}
		});
	}
};

site.inits.push('site.searchBox.init()');
